<template>
  <div class="container" v-if="content">
    <header class="jumbotron">
      <h3 >IntegratedOffers {{ content.data.name }}</h3>
    </header>
    <div>
      <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, key) in content.data" v-bind:key="key">
              <th scope="row">{{value.id}}</th>
              <td><router-link :to="{ name: 'integrated-offer-view', params: { integratedOfferId: value.id }}">{{value.name}}</router-link></td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</template>

<script>
import IntegratedOfferService from "../services/integrated-offer.service";

export default {
  name: "integrated-offers-view",
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    if (this.loggedIn) {
      // this.$router.push("/profile");
    }
    IntegratedOfferService.getIntegratedOffers().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>